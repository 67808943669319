input[type="file"] {
    display: none;
}

.upload-button-style {
    display: flex;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid;
    cursor: pointer;
}

.text {
    margin: auto;
    font-family: IBM Plex Sans Thai;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 30px; */
    letter-spacing: 0em;
    text-align: center;

}