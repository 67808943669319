.header-style {
    border-bottom: 1px solid;
    height: 100px;
}

.logo-style {
    margin-top: 12px;
}

.title-component{
    margin-top: 38px;
    margin-left: 8px;
}

.title-thai {
    font-family: IBM Plex Sans Thai;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 33px; */
    letter-spacing: 0em;
    text-align: left;
}

.title-eng {
    font-family: IBM Plex Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 36px; */
    letter-spacing: 0em;
    text-align: left;
}

.nav-menu {
    font-family: IBM Plex Sans Thai;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 33px; */
    letter-spacing: 0em;
    text-align: left;
}