.circle {
    display: flex;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 1px solid;
}

.text {
    margin: auto;
    font-family: IBM Plex Sans Thai;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 30px; */
    letter-spacing: 0em;
    text-align: center;

}