.title-how-to {
  font-family: IBM Plex Sans Thai;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 71px; */
  letter-spacing: 0em;
  text-align: center;
}

.description-step {
  font-family: IBM Plex Sans Thai;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 24px; */
  letter-spacing: 0em;
  text-align: center;
}

.circle-text {
  display: flex;
  width: 100px;
  height: 100px;
  background-color: green;
  border-radius: 50%;
}

.how-to-container {
  border-top: 1px solid;
  width: 100%;
}
