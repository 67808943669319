.title-step {
    font-family: IBM Plex Sans Thai;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 40px; */
    letter-spacing: 0em;
    text-align: center;
}

.title-description {
    font-family: IBM Plex Sans Thai;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

}