.main-container {
  margin-top: 65px;
  margin-bottom: 85px;
}

.main-title {
  font-family: IBM Plex Sans Thai;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 88px; */
  letter-spacing: 0em;
  text-align: center;
}

.select-file-circle-text {
  font-family: IBM Plex Sans Thai;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 22px; */
  letter-spacing: 0em;
  text-align: center;
}

.upload-container {
  margin-top: 65px;
}

.change-image-button-style {
  width: 166px;
  height: 45px;
  border-radius: 113px;
  background-color: white;
  cursor: pointer;
}
