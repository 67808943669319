.ant-radio-checked .ant-radio-inner {
  border-color: black !important ;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: black;
}

.ant-radio:hover .ant-radio-inner {
  border-color: black;
}
