.footer-style {
    border-top: 1px solid;
}

.footer-container {
    /* position:absolute;
    bottom:0; */
}
.title-nrct {
    font-family: IBM Plex Sans Thai;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: 44px;
letter-spacing: 0em;
text-align: left;

}
.description-nrct {
    font-family: IBM Plex Sans Thai;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    /* line-height: 28px; */
    letter-spacing: 0em;
    text-align: left;
}

.first-col {
    margin-top: 40px;
}

.second-col {
    margin-top: 42px;
    padding-right: 55px;
}

.third-col {
    margin-top: 42px;
}

.title-col {
    font-family: IBM Plex Sans Thai;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 29px; */
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
}
.description-contact {
    font-family: IBM Plex Sans Thai;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 25px; */
    letter-spacing: 0em;
    text-align: left;
}

.description-col{
    font-family: IBM Plex Sans Thai;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 17px; */
    letter-spacing: 0em;
    text-align: left;
}
